import { addPropertyControls, ControlType } from "framer"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import React, { useState, useEffect } from "react"
import bcrypt from "bcryptjs"

/** @framerDisableUnlink */

const useStore = createStore({ showContent: false })

export default function PasswordForm(props) {
    const {
        master,
        url,
        inputProperties,
        buttonProperties,
        fontProperties,
        formProperties,
    } = props

    // Using default password if no hash value is provided
    const hashedCorrectPassword = props.url
    const hashedMasterPassword = props.master

    //inputProperties.hashValue || URL.

    const [password, setPassword] = useState("")
    const [showError, setShowError] = useState(false)

    const [store, setStore] = useStore()

    useEffect(() => {
        // This function will run when the component is first loaded
        setStore({
            showContent: false,
        })
    }, [])

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        bcrypt.compare(password, hashedCorrectPassword, (err, match) => {
            if (err) {
                bcrypt.compare(password, hashedMasterPassword, (er, matc) => {
                    if (er) {
                        console.error("Error validating tokens:", er)
                        return
                    }
                    if (matc) {
                        setStore({ showContent: true })
                        console.log("Token validation successful")
                    } else {
                        setShowError(true)
                        console.log("Incorrect token")
                    }
                })
            }
            if (match) {
                setStore({ showContent: true })
                console.log("Token validation successful")
            } else {
                bcrypt.compare(password, hashedMasterPassword, (er, matc) => {
                    if (er) {
                        console.error("Error validating tokens:", er)
                        return
                    }
                    if (matc) {
                        setStore({ showContent: true })
                        console.log("Token validation successful")
                    } else {
                        setShowError(true)
                        console.log("Incorrect token")
                    }
                })
            }
        })
    }

    // Helper functions to extract styles
    const getBorderRadius = (properties) =>
        properties.isMixedRadius
            ? `${properties.topLeft}px ${properties.topRight}px ${properties.bottomRight}px ${properties.bottomLeft}px`
            : `${properties.radius}px`

    const getPadding = (properties) =>
        properties.isMixedPadding
            ? `${properties.top}px ${properties.right}px ${properties.bottom}px ${properties.left}px`
            : `${properties.padding}px`

    const getBorderColor = (properties) =>
        showError ? properties.errorColor : properties.borderColor

    const getErrorMargin = (properties) =>
        properties.isMixedPadding
            ? `4px 0px 0px ${properties.left}px`
            : `4px 0px 0px ${properties.padding}px`

    // Extracting styles for better readability
    const formStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        height: "fit-content",
        transition: "opacity 1s ease",
        width: "100%",
    }

    const inputBorderRadius = getBorderRadius(inputProperties)
    const inputPadding = getPadding(inputProperties)
    const inputBorder = `1px solid ${getBorderColor(inputProperties)}`

    const inputStyle = {
        display: "flex",
        width: "200px",
        //flex: 1,
        minWidth: "0px",
        padding: inputPadding,
        borderRadius: inputBorderRadius,
        background: inputProperties.fillColor,
        color: showError
            ? inputProperties.errorColor
            : inputProperties.textColor,
        border: inputBorder,
        fontSize: `${fontProperties.fontSize}px`,
        fontWeight: fontProperties.font.fontWeight,
        fontFamily: fontProperties.font.fontFamily,
        minHeight: "15px",
        outline: "none",
        boxShadow:
            "rgba(0, 0, 0, 0.12) 0px 0.637473px 1.147451px -1.125px, " +
            "rgba(0, 0, 0, 0.10) 0px 1.930942px 3.475695px -2.25px, " +
            "rgba(0, 0, 0, 0.092) 0px 5.104227px 9.187608px -3.375px, " +
            "rgba(0, 0, 0, 0.03) 0px 16px 28.8px -4.5px",
    }

    const buttonBorderRadius = getBorderRadius(buttonProperties)
    const buttonPadding = getPadding(buttonProperties)

    const buttonStyle = {
        padding: buttonPadding,
        borderRadius: buttonBorderRadius,
        border: "none",
        background: buttonProperties.fillColor,
        color: buttonProperties.textColor,
        fontSize: `${fontProperties.fontSize}px`,
        fontWeight: fontProperties.font.fontWeight,
        fontFamily: fontProperties.font.fontFamily,
        cursor: "pointer",
        minHeight: "15px",
        boxShadow:
            "rgba(0, 0, 0, 0.26) 0px 0.637473px 1.147451px -1.125px, " +
            "rgba(0, 0, 0, 0.24) 0px 1.930942px 3.475695px -2.25px, " +
            "rgba(0, 0, 0, 0.192) 0px 5.104227px 9.187608px -3.375px, " +
            "rgba(0, 0, 0, 0.03) 0px 16px 28.8px -4.5px",
    }

    const layout = formProperties.rowLayout ? "row" : "column"
    const formPadding = getPadding(formProperties)

    const errorStyle = {
        color: inputProperties.errorColor,
        margin: getErrorMargin(formProperties),
        fontSize: `${fontProperties.fontSize - 4}px`,
        fontWeight: fontProperties.font.fontWeight,
        fontFamily: fontProperties.font.fontFamily,
    }

    const wrapperStyle = {
        display: "flex",
        flexDirection: layout,
        width: "100%",
        padding: formPadding,
        gap: `${formProperties.gap}px`,
    }

    const renderChildComponent = () => {
        const activeComponentIndex = props.childrenMediaQueries.findIndex(
            (mediaQuery) => window.innerWidth > mediaQuery - 1
        )

        const activeChild =
            props.childrenMediaQueries.length <= 1
                ? props.children
                : props.children[activeComponentIndex]

        return activeChild || null
    }

    const inputClass = showError ? "input-error" : "input-default"

    const renderPasswordForm = () => {
        return (
            <form onSubmit={handleSubmit} style={formStyle}>
                <style>{`
                    .input-default::placeholder {
                        color: ${inputProperties.placeholderTextColor};
                    }
                    .input-error::placeholder {
                        color: ${inputProperties.errorColor};
                    }
                `}</style>
                <div style={wrapperStyle}>
                    <input
                        type="password"
                        placeholder={inputProperties.placeholderText}
                        style={inputStyle}
                        className={inputClass}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <button type="submit" style={buttonStyle}>
                        {buttonProperties.labelText}
                    </button>
                </div>
                {showError && (
                    <span style={errorStyle}>{inputProperties.errorText}</span>
                )}
            </form>
        )
    }

    return (
        <>{store.showContent ? renderChildComponent() : renderPasswordForm()}</>
    )
    return
    {
        props.url
    }
}

PasswordForm.defaultProps = {
    master: "Default Hash",
    url: "Default URL",
    inputProperties: {
        placeholderText: "Enter your password",
        hashValue: "",
        fillColor: "#fff",
        borderColor: "#ccc",
        textColor: "#000",
        placeholderTextColor: "#d3d3d3",
        errorColor: "#ee4444",
        errorText: "Incorrect password",
        radius: 10,
        isMixedRadius: false,
        topLeft: 10,
        topRight: 10,
        bottomRight: 10,
        bottomLeft: 10,
        padding: 10,
        isMixedPadding: false,
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
    },
    buttonProperties: {
        labelText: "Submit",
        fillColor: "#000",
        textColor: "#fff",
        radius: 10,
        isMixedRadius: false,
        topLeft: 10,
        topRight: 10,
        bottomRight: 10,
        bottomLeft: 10,
        padding: 10,
        isMixedPadding: false,
        top: 10,
        right: 20,
        bottom: 10,
        left: 20,
    },
    fontProperties: {
        font: {
            family: "Myriad Pro Light",
            style: "normal",
            weight: 400,
            size: 16,
            lineHeight: 1.2,
        },
        fontSize: 16,
    },
    formProperties: {
        isMixedPadding: false,
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
        rowLayout: true,
        gap: 12,
    },
    childrenMediaQueries: [],
    children: null,
}

addPropertyControls(PasswordForm, {
    master: { type: ControlType.String, title: "Master" },
    url: { type: ControlType.String, title: "URL" },
    inputProperties: {
        title: "Input",
        type: ControlType.Object,
        controls: {
            placeholderText: {
                title: "Placeholder",
                type: ControlType.String,
            },
            hashValue: {
                title: "Hash Value",
                description:
                    "[Run a value through this for a hash](https://bcrypt.online)",
                type: ControlType.String,
            },
            fillColor: {
                title: "Fill",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties.fillColor,
            },
            borderColor: {
                title: "Border",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties.borderColor,
            },
            textColor: {
                title: "Text",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties.textColor,
            },
            placeholderTextColor: {
                title: "Placeholder Text",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties
                        .placeholderTextColor,
            },
            errorColor: {
                title: "Error",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties.errorColor,
            },
            errorText: {
                title: "Error Text",
                type: ControlType.String,
                defaultValue:
                    PasswordForm.defaultProps.inputProperties.errorText,
            },
            radius: {
                type: ControlType.FusedNumber,
                title: "Radius",
                defaultValue: 10,
                toggleKey: "isMixedRadius",
                toggleTitles: ["All", "Individual"],
                valueKeys: ["topRight", "topLeft", "bottomLeft", "bottomRight"],
                valueLabels: ["TR", "TL", "BL", "BR"],
                min: 0,
            },
            padding: {
                type: ControlType.FusedNumber,
                title: "Padding",
                defaultValue: 10,
                toggleKey: "isMixedPadding",
                toggleTitles: ["All", "Individual"],
                valueKeys: ["top", "right", "bottom", "left"],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
        },
    },
    buttonProperties: {
        title: "Button",
        type: ControlType.Object,
        controls: {
            labelText: {
                title: "Label",
                type: ControlType.String,
            },
            fillColor: {
                title: "Fill",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.buttonProperties.textColor,
            },
            textColor: {
                title: "Text",
                type: ControlType.Color,
                defaultValue:
                    PasswordForm.defaultProps.buttonProperties.textColor,
            },
            radius: {
                type: ControlType.FusedNumber,
                title: "Radius",
                defaultValue: 10,
                toggleKey: "isMixedRadius",
                toggleTitles: ["All", "Individual"],
                valueKeys: ["topRight", "topLeft", "bottomLeft", "bottomRight"],
                valueLabels: ["TR", "TL", "BL", "BR"],
                min: 0,
            },
            padding: {
                type: ControlType.FusedNumber,
                title: "Padding",
                defaultValue: 10,
                toggleKey: "isMixedPadding",
                toggleTitles: ["All", "Individual"],
                valueKeys: ["top", "right", "bottom", "left"],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
        },
    },
    fontProperties: {
        title: "Font",
        type: ControlType.Object,
        controls: {
            font: {
                title: "Font",
                type: ControlType.Font,
                defaultValue: PasswordForm.defaultProps.fontProperties.font,
            },
            fontSize: {
                title: "Font Size",
                type: ControlType.Number,
                defaultValue: PasswordForm.defaultProps.fontProperties.fontSize,
            },
        },
    },
    formProperties: {
        title: "Form",
        type: ControlType.Object,
        controls: {
            padding: {
                type: ControlType.FusedNumber,
                title: "Padding",
                defaultValue: 10,
                toggleKey: "isMixedPadding",
                toggleTitles: ["All", "Individual"],
                valueKeys: ["top", "right", "bottom", "left"],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
            rowLayout: {
                title: "Layout",
                type: ControlType.Boolean,
                enabledTitle: "Row",
                disabledTitle: "Column",
                defaultValue:
                    PasswordForm.defaultProps.formProperties.rowLayout,
            },
            gap: {
                title: "Gap",
                type: ControlType.Number,
                defaultValue: PasswordForm.defaultProps.formProperties.gap,
                min: 0,
            },
        },
    },
    childrenMediaQueries: {
        title: "Media Queries",
        type: ControlType.Array,
        propertyControl: {
            title: "Min Media Width",
            type: ControlType.Number,
            defaultValue: 0,
        },
        defaultValue: [],
    },
    children: {
        title: "Children",
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.ComponentInstance,
        },
        defaultValue: [],
    },
})
